<template>
   <main>
      <section class="checkout-area py-md-5 py-3">
         <div class="container">
            <div class="row">
               <div class="col-lg-6">
                  <div class="checkbox-form">
                     <h3 class="mb-0">Carrinho</h3>
                     <produto v-for="(produto, index) in carrinho.itens" :key="index" :produto="produto" :index="index" :page="'FINALIZAR'" />
                  </div>
               </div>
               <div class="col-lg-6">
                  <div class="checkbox-form" v-if="dados != null">
                     <h3 class="mb-0">Dados cadastrados</h3>
                     <div class="col-12">
                        <div class="your-order-table table-responsive">
                           <table>
                              <tbody>
                                 <tr class="cart_item">
                                    <td class="w-50 product-name regulaTexto">
                                       <strong class="product-quantity"><i class="fal fa-id-card color-theme font-12 me-1"></i> CPF</strong>
                                    </td>
                                    <td class="w-50 product-total regulaTexto py-2">
                                       <input type="text" class="form-control font-14" maxlength="250" v-model="dados.user.cpf" v-mask="['###.###.###-##', '##.###.###/####-##']" />
                                    </td>
                                 </tr>
                                 <tr class="cart_item">
                                    <td class="w-50 product-name regulaTexto">
                                       <strong class="product-quantity"><i class="fab fa-whatsapp color-theme font-12 me-1"></i> WhatsApp</strong>
                                    </td>
                                    <td class="w-50 product-total regulaTexto py-2">
                                       <input type="text" class="form-control font-14" maxlength="250" v-model="dados.user.telefone" v-mask="'(##) #####-####'" />
                                    </td>
                                 </tr>
                                 <tr class="cart_item">
                                    <td class="w-50 product-name regulaTexto">
                                       <strong class="product-quantity"><i class="far fa-address-book color-theme font-12 me-1"></i> Nome para contato</strong>
                                    </td>
                                    <td class="w-50 product-total regulaTexto py-2">
                                       <input type="text" class="form-control font-14" maxlength="250" v-model="dados.user.nomeContato" />
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>

                     <h3 class="pt-5 mb-0 regulaTexto">Entrega <small class="text-danger font-13 ms-2">(Todos os dias das 08:00 ás 20:00)</small></h3>
                     <div class="col-12">
                        <div class="your-order-table table-responsive">
                           <table>
                              <tbody>
                                 <tr class="cart_item">
                                    <td class="w-50 product-name regulaTexto">
                                       <strong class="product-quantity"><i class="far fa-truck color-theme font-12 me-1"></i> Tipo de entrega</strong>
                                    </td>
                                    <td class="w-50 product-total regulaTexto py-2">
                                       <select class="form-select font-14" v-model="dadosCompra.tipoEntrega" @change="calcularFrete">
                                          <option>Retirar na loja</option>
                                          <option>Entregar no meu domicílio</option>
                                       </select>
                                    </td>
                                 </tr>
                                 <tr class="cart_item" v-if="dadosCompra.tipoEntrega == 'Entregar no meu domicílio'">
                                    <td class="w-50 product-name regulaTexto">
                                       <strong class="product-quantity"><i class="far fa-road color-theme font-12 me-1"></i> Rua</strong>
                                    </td>
                                    <td class="w-50 product-total regulaTexto py-2">
                                       <input type="text" class="form-control font-14" maxlength="250" v-model="dados.user.rua" />
                                    </td>
                                 </tr>
                                 <tr class="cart_item" v-if="dadosCompra.tipoEntrega == 'Entregar no meu domicílio'">
                                    <td class="w-50 product-name regulaTexto">
                                       <strong class="product-quantity"><i class="far fa-map-marker-alt color-theme font-12 me-1"></i> Número</strong>
                                    </td>
                                    <td class="w-50 product-total regulaTexto py-2">
                                       <input type="text" class="form-control font-14" maxlength="250" v-model="dados.user.numero" />
                                    </td>
                                 </tr>
                                 <tr class="cart_item" v-if="dadosCompra.tipoEntrega == 'Entregar no meu domicílio'">
                                    <td class="w-50 product-name regulaTexto">
                                       <strong class="product-quantity"><i class="far fa-map-marked color-theme font-12 me-1"></i> Bairro</strong>
                                    </td>
                                    <td class="w-50 product-total regulaTexto py-2">
                                       <input type="text" class="form-control font-14" maxlength="250" v-model="dados.user.bairro" />
                                    </td>
                                 </tr>
                                 <tr class="cart_item" v-if="dadosCompra.tipoEntrega == 'Entregar no meu domicílio'">
                                    <td class="w-50 product-name regulaTexto">
                                       <strong class="product-quantity"><i class="far fa-location-circle color-theme font-12 me-1"></i> CEP</strong>
                                    </td>
                                    <td class="w-50 product-total regulaTexto py-2">
                                       <input type="text" class="form-control font-14" maxlength="250" v-model="dados.user.cep" />
                                    </td>
                                 </tr>
                                 <tr class="cart_item" v-if="dadosCompra.tipoEntrega == 'Entregar no meu domicílio'">
                                    <td class="w-50 product-name regulaTexto">
                                       <strong class="product-quantity"><i class="far fa-location color-theme font-12 me-1"></i> Ponto de Refêrencia</strong>
                                    </td>
                                    <td class="w-50 product-total regulaTexto py-2">
                                       <input type="text" class="form-control font-14" maxlength="250" v-model="dados.user.pontoDeReferencia" />
                                    </td>
                                 </tr>
                                 <tr class="cart_item" v-else>
                                    <td class="w-50 product-name regulaTexto">
                                       <strong class="product-quantity"><i class="far fa-store color-theme font-12 me-1"></i> Loja para retirada</strong>
                                    </td>
                                    <td class="w-50 product-total regulaTexto py-2">
                                       <select class="form-select font-14" v-model="dadosCompra.lojaRetirada">
                                          <option value="1">Loja Matriz - Av. Venezuela, 1005</option>
                                          <option value="2" selected>Loja Santa Terezinha - R. Jaguariaíva, 1360</option>
                                          <!-- <option value="3" selected>Loja Eucaliptos - R. Pesegueiro, 743</option>
                                          <option value="4" selected>Loja Iguaçu - R. Piquiri, 288</option> -->
                                       </select>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>

                     <h3 class="pt-5 mb-0">Pagamento</h3>
                     <div class="col-12">
                        <div class="checkbox-form">
                           <div class="col-12">
                              <div class="your-order-table table-responsive">
                                 <table>
                                    <tbody>
                                       <tr class="cart_item">
                                          <td class="w-50 product-name regulaTexto">
                                             <strong class="product-quantity"><i class="far fa-credit-card color-theme font-12 me-1"></i> Forma de pagamento</strong>
                                          </td>
                                          <td class="w-50 product-total regulaTexto py-2">
                                             <select class="form-select font-14" v-model="dadosCompra.formaPgto">
                                                <option>Dinheiro</option>
                                                <option>Cartão de crédito</option>
                                                <option>Cartão de débito</option>
                                             </select>
                                          </td>
                                       </tr>
                                       <tr class="cart_item" v-if="dadosCompra.tipoEntrega == 'Entregar no meu domicílio'">
                                          <td class="w-50 product-name regulaTexto">
                                             <strong class="product-quantity"><i class="far fa-house-return color-theme font-12 me-1"></i> Valor de frete</strong>
                                          </td>
                                          <td class="w-50 product-total regulaTexto text-end">
                                             <span class="amount"><strong>R$ {{ parseFloat(valorFrete).toFixed(2) }}</strong></span>
                                          </td>
                                       </tr>
                                       <tr class="cart_item">
                                          <td class="w-50 product-name regulaTexto">
                                             <strong class="product-quantity"><i class="far fa-money-bill-wave-alt color-theme font-12 me-1"></i> Valor da compra</strong>
                                          </td>
                                          <td class="w-50 product-total regulaTexto text-end">
                                             <span class="amount">
                                                <strong>R$ {{ parseFloat(carrinho.valorTotal).toFixed(2) }}</strong>
                                             </span>
                                          </td>
                                       </tr>
                                       <tr class="cart_item">
                                          <td class="w-50 product-name regulaTexto">
                                             <strong class="product-quantity"><i class="fas fa-money-bill-wave-alt color-theme font-12 me-1"></i> Valor total</strong>
                                          </td>
                                          <td class="w-50 product-total regulaTexto text-end">
                                             <span class="amount">
                                                <strong>R$ {{ (parseFloat(carrinho.valorTotal) + parseFloat(valorFrete)).toFixed(2) }}</strong>
                                             </span>
                                          </td>
                                       </tr>
                                       <tr class="cart_item">
                                          <td class="w-100 product-total regulaTexto py-2">
                                             <textarea class="form-control font-14" maxlength="250" v-model="dadosCompra.observacoes" placeholder="Observações sobre o pedido ou forma de pagamento..."></textarea>
                                          </td>
                                       </tr>
                                       <tr class="cart_item">
                                          <td class="w-100 product-total text-end">
                                             <button class="btn bg-theme font-14" @click="finalizar">Finalizar</button>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </main>
</template>

<script>

import router from '@/router'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import produto from '@/components/home/Carrinho.vue'

export default {
	name: 'Finalizar',
   data: function() {
		return {
			dados: null,
         dadosCompra: {'formaPgto': 'Dinheiro', 'observacoes': '', 'tipoEntrega': 'Retirar na loja', 'lojaRetirada': '2'},
         valorFrete: 0
		}
	},
   computed: {
		... mapState({
         dadosUsuario: state => state.dadosUsuario,
         logado: state => state.logado,
         carrinho: state => state.carrinho,
         urlRest: state => state.urlRest
		})
	},
   components: {
		produto
	},
   methods: {
      calcularFrete : function () {
         if (this.dadosCompra.tipoEntrega == 'Entregar no meu domicílio') {
            this.valorFrete = 6
         } else {
            this.valorFrete = 0
         }
      },
      finalizar : function () {
         if (this.dadosCompra.tipoEntrega == 'Entregar no meu domicílio') {
            if (this.dados.user.rua.length < 5) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Digite ao menos 5 caracteres na rua!'
               });

               return
            } else if (this.dados.user.numero.length < 1) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Digite ao menos 1 caractere no número!'
               });

               return
            } else if (this.dados.user.bairro.length < 5) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Digite ao menos 5 caracteres no bairro!'
               });

               return
            } else if (this.dados.user.cep.length < 5) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Digite ao menos 5 caracteres no CEP!'
               });

               return
            }
         } else {
            if (this.dados.user.cpf.length < 14) {
               ref.$toast.fire({
                  icon: 'error',
                  title: 'CPF inválido!'
               });
   
               return
            } else if (this.dados.user.telefone.length != 15) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'WhatsApp inválido!'
               });
   
               return
            }
         }
            
         let ref = this;
         let jsonEnvio = {
            'carrinho': ref.carrinho.itens,
            'usuario': ref.dados.user
         }

         Swal.fire({
            icon: 'warning',
            title: 'Realizar pedido?',
            text: 'Produtos sujeitos à disponibilidade de estoque.',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar'

         }).then((result) => {
            if (result.isConfirmed) {
               ref.$store.dispatch('alternarTelaCarregamento', true)
      
               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'product/checkout',
                  headers: { 
                     'Content-Type': 'application/json'
                  },
                  data: jsonEnvio,
                  params: {
                     tipoEntrega: ref.dadosCompra.tipoEntrega,
                     lojaRetirada: ref.dadosCompra.tipoEntrega != 'Retirar na loja' ? null : ref.dadosCompra.lojaRetirada,
                     formaPgto: ref.dadosCompra.formaPgto,
                     frete: ref.valorFrete,
                     idUser: ref.dadosUsuario.cliente.id,
                     observacoes: ref.dadosCompra.observacoes
                  }
               }).then(function () {
                  ref.$store.dispatch('limparCarrinho')
                  router.push('/').catch(function(){})
      
                  Swal.fire({
                     icon: 'success',
                     title: 'Pedido realizado!'
                  });
               }).catch(function (error) {
                  if (error.response != undefined) {
                     if (error.response.status == 400) {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Endereço inválido!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        });
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     });
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         })
      },
      buscarUsuario : function () {
         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: this.urlRest +'user/getuser',
            params: {
               idUser: this.dadosUsuario.cliente.id
            }
         }).then(function (response) {
            ref.dados = response.data

         }).catch(function (error) {
            if (error.response != undefined) {
               ref.$toast.fire({
                  icon: 'error',
                  title: 'Erro: ' + error.response.status
               });
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      }
   },
   created() {
      this.$store.dispatch('toggleCarrinho', false)

      if (this.logado.cliente) {
         this.buscarUsuario()

      } else {
         Swal.fire({
            icon: 'warning',
            title: 'Entre para continuar!',
            showCancelButton: false,
            confirmButtonText: 'Entrar'

         }).then(function () {
            router.push('/login').catch(function(){})
         })
      }
   }
}

</script>